import './App.css';
import {Button, Grid, Rating, styled, TextField, Typography} from "@mui/material";
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import {useEffect, useMemo, useState} from "react";
import {useLocation, useParams} from "react-router";
import carVideo from './carVideo.mp4';
import * as React from "react";

function App() {
    let params = useParams();
    function useQuery() {
        const { search } = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery();

    useEffect(() => {
        const video = document.querySelector('.videoTag');
        video.play()
            .catch(error => {
                // Autoplay was prevented
                console.error('Autoplay prevented:', error);
            });
    }, []);


    const StyledRating = styled(Rating)(({ theme }) => ({
        '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
            color: 'white',
        },
    }));

    const iconStyle = {width: 65, height: 65}

    const customIcons = {
        1: {
            icon: <SentimentVeryDissatisfiedIcon color="error" size={'large'} style={iconStyle} />,
            label: 'Много недоволен',
        },
      /*  2: {
            icon: <SentimentDissatisfiedIcon color="error" size={'large'} style={iconStyle}/>,
            label: 'Недоволен',
        },*/
        2: {
            icon: <SentimentSatisfiedIcon color="warning" size={'large'} style={iconStyle}/>,
            label: 'Неутрално',
        },
/*        4: {
            icon: <SentimentSatisfiedAltIcon color="success" size={'large'} style={iconStyle}/>,
            label: 'Доволен',
        },*/
        3: {
            icon: <SentimentVerySatisfiedIcon color="success" size={'large'} style={iconStyle}/>,
            label: 'Много доволен',
        },
    };

    function IconContainer(props) {
        const { value, ...other } = props;
        return <span {...other}>{customIcons[value].icon}</span>;
    }

    const LULIN_URL = 'https://g.page/r/CVyMew_mtFCnEBM/review'
    const MLADOST_URL = 'https://g.page/r/CT2q_cZ7_y7VEBM/review'

    const POSITIVE_RATING = [3];
    const NEGATIVE_RATING = [1, 2];


    const [ratingToken, setRatingToken] = useState(null);

    const [warehouseId, setWarehouseId] = useState(1);
    const [clientId, setClientId] = useState(1);
    const [clientData, setClientData] = useState(1);
    const [carId, setCarId] = useState(1);

    const [rating, setRating] = useState(null);
    const [comment, setComment] = useState();


    const [hasVoted, setHasVoted] = useState(null);
    const [hasVotedRating, setHasVotedRating] = useState(null);

    useEffect(() => {
        if (POSITIVE_RATING.includes(rating)) {
            window.open(
                warehouseId === 1 ? LULIN_URL : MLADOST_URL,
                "_self")
            onRate()
        }
    }, [rating])

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL}/api/rating?` + new URLSearchParams({
            'hash': query.get('hash')
        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(result => result.json())
            .then((result) => {
                if (result) {

                    setClientId(result.client_id)
                    setHasVotedRating(result.rating)

                    const orderResult = result.order;
                    if (orderResult) {
                        setWarehouseId(orderResult.warehouse_id)
                        setCarId(orderResult.vehicle_id)
                    }

                    const clientResult = result.client;
                    setClientData(clientResult)

                }
            })
    }, [])

    const onRate = () => {
        fetch(`${process.env.REACT_APP_URL}/api/rating`, {
            method: "POST",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "hash": query.get('hash'),
                "rating": rating,
                "comment": comment
            })
        })
            .then(result => result.json())
            .then((result) => {
                if (result) {
                    setHasVoted(true)
                }
            })
    }

    return (<div style={{width: '100%',
            height: '100%',
            position: 'absolute',
            padding:0,
            margin:0,
            left: 0,
            top: 0,
            zIndex: -1000,
            overflow:'hidden'
    }}>

            <div style={{height: '100vh', zIndex: 999}}>

                <img src={'https://cdncloudcart.com/13012/files/image/6plus-logo-200.png'}
                    style={{position: 'absolute', zIndex: 999, top: 40, left: 20}}/>

                <Grid container direction='column' spacing={1} justifyContent="center"
                      alignItems="center" style={{height: '100vh'}}>

                    {
                        (hasVoted || (hasVotedRating === 3)) ?
                            <Grid item style={{zIndex: 999, padding: 20,
                                borderRadius: 25,
                                background: 'rgba(107,106,106,0.41)'}}>
                                <Typography style={{color: 'white'}}>
                                    Уважаеми {clientData.name},<br/>
                                    Благодарим, че оценихте автосервиз 6+. Мнението Ви е важно за нас!
                                </Typography>

                            </Grid>
                            :
                            <>
                                <Grid item style={{zIndex: 999, padding: 20,
                                    borderRadius: 25,
                                    background: 'rgba(107,106,106,0.41)'}}>

                                    <Grid container direction='column' spacing={1} justifyContent="center"
                                          alignItems="center">

                                        {clientData ? <Grid item>
                                            <Typography style={{color: 'white'}}>
                                                Уважаеми {clientData.name},<br/>
                                                Моля оценете вашето посещение в автосервиз 6+.
                                            </Typography>
                                        </Grid>  : null}

                                        <Grid item>
                                            <StyledRating
                                                name="highlight-selected-only"
                                                IconContainerComponent={IconContainer}
                                                getLabelText={(value) => customIcons[value].label}
                                                highlightSelectedOnly
                                                value={rating}
                                                max={3}
                                                style={{  iconFilled: {
                                                        color: 'red',
                                                    },}}
                                                onChange={(e) => {
                                                    let newValue = e.target.value;
                                                    setRating(Number(newValue));
                                                    console.log(newValue)
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                </Grid>

                                {NEGATIVE_RATING.includes(rating) ? <React.Fragment style={{zIndex: 999}}>
                                    <Grid item style={{height: 1}} style={{zIndex: 999}}/>
                                    <Grid item style={{zIndex: 999}}>
                                        <TextField style={{minWidth: 300}}
                                                   sx={{
                                                       fieldset: { borderColor: "white", color: 'white' }
                                                   }}
                                                   InputProps={{ style: { color: "white" } }}
                                                   InputLabelProps={{ style: { color: "white" } }}
                                                   focus multiline rows={4} label={'Как можем да се подобрим?'} value={comment}
                                                   onChange={(e) => setComment(e.target.value)}/>
                                    </Grid>
                                    <Grid item style={{zIndex: 999}}>
                                        <Button variant={'contained'} onClick={() => {
                                            onRate()
                                        }}>Изпрати</Button>
                                    </Grid>
                                </React.Fragment> : null}

                            </>
                    }

                </Grid>
            </div>


            <video style={{background: 'black',
                'min-height': '100%',
                //min-width:100%; - if fit to width
                'position':'absolute',
                bottom:0,
                left:0

            }} className='videoTag' playsInline autoPlay loop muted src={carVideo}>
            </video>
        </div>
    );
}

export default App;
